	<template>
  <div
    class="recharge-page"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\''"
  >
    <div class="header">
      <img class="member-bg" :src="require('@/assets/images/bg-index.png')" />
      <div class="credit">
        <span class="title">{{ PAGELANG.可提现收益 }}</span>
        <span class="price">{{ availableMoney }}</span>
      </div>
    </div>

    <div class="credit-block">
      <van-form @submit="onSubmit">
        <div class="van-cell van-field">
          <div class="van-cell__title van-field__label van-field__label--top">
            {{ PAGELANG.温馨提示 }}
          </div>
          <div class="van-cell__value van-field__value">
            <div
              class="van-field__body"
              style="width: 100%; color: #f66; text-align: left"
            >
              {{ PAGELANG.只能在每月1至5号申请提现 }}
            </div>
          </div>
        </div>
<!--        <van-field
          v-model="Account"
          is-link
          readonly
          name="picker"
          :label="PAGELANG.提现账户"
          :placeholder="PAGELANG.选择提现账户"
          @click="showAccount = true"
          label-align="top"
          :rules="[{ required: true, message: PAGELANG.请选择一个提现账户 }]"
        /> -->
<!--        <van-popup v-model:show="showAccount" position="bottom">
          <van-picker
            name="AccountID"
            :title="PAGELANG.提现账户"
            :columns="agentInfo.AccountList"
            v-model="AccountID"
            @confirm="onConfirm"
            @cancel="showAccount = false"
          />
        </van-popup> -->
		
		<van-field
		          readonly
		          v-model="AcountName"
		          name="AcountName"
		          label="收款人姓名"
		          label-align="top"
		          :rules="[{ required: true, message: PAGELANG.请选择收款人姓名 }]"
		/>
		
		<van-field
		          readonly
		          v-model="Acount"
		          name="Acount"
		          label="提现账户"
		          label-align="top"
		          :rules="[{ required: true, message: PAGELANG.请选择一个提现账户 }]"
		/>
		<van-field
		          readonly
		          v-model="bankName"
		          name="bankName"
		          label="提现账户银行"
		          label-align="top"
		          :rules="[{ required: true, message: PAGELANG.请选择一个提现账户 }]"
		/>
		<van-field
		          readonly
		          v-model="exchangeMoneyName"
		          name="exchangeMoneyName"
		          label="提现账户币种"
		          label-align="top"
		          :rules="[{ required: true, message: PAGELANG.请选择一个提现账户 }]"
		/>
		
		<van-field
		
		  v-if="agentInfo.nickName!=AcountName"
		  v-model="transferReason"
		  name="account"
		  label="代收款原因"
		  label-align="top"
		  placeholder="請輸入代收款原因"
		  :rules="[{ required:true, message:PAGELANG.代收款原因不能为空 }]"
		/>
        <div v-if="agentInfo.nickName!=AcountName" style="margin-top:10px;padding:13px;">
			<div style="margin-bottom:10px;">姓名非本人需上傳合同</div>
				<van-uploader
				  v-model="TradeProof"
				  multiple
				  :max-count="1"
				  :upload-text="PAGELANG.tradeprooftext"
				  :before-read="beforeRead"
				  :after-read="uploadTradeProof"
				  class="trade-proof"
				  style="text-align: center;"
				/>
			<div
			  v-if="showTradeProofMsg"
			  class="van-field__error-message"
			  style="width:100%"
			>
			  {{ PAGELANG.relestradeproof }}
			</div>
		</div>
		

        <van-button
          type="primary"
          block
          color="var(--van-card-price-color)"
          native-type="submit"
          class="btn-submit"
          >{{ PAGELANG.申请提现 }}</van-button
        >
      </van-form>
    </div>
  </div>

</template>

<script>
import { closeToast, showLoadingToast, showToast } from "vant";
import { getCookie } from "../util";

export default {
  data() {
    return {
      agentInfo: {},
      showAccount: false,
      Amount: "",
      rules: {},
      withdraw: {},
      labelWidth: "120px",
	  list:[],
	  Acount:"",
	  AcountName:"",
	  exchangeMoneyName:"",
	  bankName:"",
	  availableMoney:0,
	  ticketUrls:[],
	  showTradeProofMsg:false,
	  TradeProof: [],
	  transferReason:"",//代收款原因
	  
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }

    this.PAGELANG = this.LANG.pages.income;
    this.SYSLANG = this.LANG.system;

    if (this.langcode != "zh") {
      this.labelWidth = "190px";
    }

    this.rules = {
      AccountID: [
        { required: true, message: this.PAGELANG.请选择一个提现账户 },
      ],
      Amount: [
        {
          required: true,
          validator: this.checkAmountPC,
        },
      ],
    };

    this.getMemberInfo();
  },
  methods: {
	  
	uploadTradeProof(file) {
	  this.showTradeProofMsg = false;
	  console.log(file);
	  const forms = new FormData()
	  forms.append('file', file.file)
	  this.axios.post(this.actions.upload,forms).then(res=>{
		  console.log(res)
		  this.ticketUrls.push(res.data.data.url)
	  })
	},  
	beforeRead(file) {
	  if (
	    file.type !== "image/jpeg" &&
	    file.type !== "image/png" &&
	    file.type !== "image/gif"
	  ) {
	    showToast(this.PAGELANG.uploadfile);
	    return false;
	  }
	  return true;
	},
	
	
    getMemberInfo() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });
	  //获取个人信息
	  this.axios.get(this.actions.memberInfo).then((response) => {
	    closeToast();
	   let { code, message, data } = response.data;
	    if (code == "200") {
	      this.agentInfo = data;						
	    } else {
	      showToast({
	        message: code == "NOTLOGIN" ? this.SYSLANG[message] : this.GOODSLANG[message],
	        forbidClick: true,
	        onClose: () => {
	          if (code == "NOTLOGIN") {
	            this.$router.replace({
	              path: "/login",
	            });
	          }
	        },
	      });
	    }
	  });
	  
	  this.axios.get(this.actions.accountsList,{params:{type:2}}).then((res) => {
	     closeToast();
	  		 let code = res.data.code;
	  		 if(code==200){
	  			if(res.data.data[0].account==""){
	  				//this.isAdd=true
	  				}else{
	  				//this.list.push(res.data.data[0]);
					console.log('this.Amount',this.list)
					this.Acount=res.data.data[0].account
					this.AcountName=res.data.data[0].name
					this.bankName=res.data.data[0].bankName
					this.exchangeMoneyName=res.data.data[0].exchangeMoneyName
	  			}
	  		 }
	  });
	   this.axios.get(this.actions.memberState).then((res) => {
		     this.availableMoney=res.data.data.availableMoney/100
		   
	   })
	  
	  
    },
    formatMoney(money) {
      if (!money) return "0.00";
      let integer = Math.floor(money).toString();
      let thousands = integer
        .split("")
        .reverse()
        .join("")
        .replace(/(\d{3})/g, "$1.")
        .replace(/,$/, "");

      if (thousands.substr(-1, 1) == ".") {
        thousands = thousands.substring(0, thousands.length - 1);
      }

      return thousands.split("").reverse().join("");
    },
    formatter(val) {
      return val.replace(/[^\d]*/g, "").replace(/(?=(\B)(\d{3})+$)/g, ".");
    },
    onConfirm(val) {
      console.log(val);
      this.Account = val.selectedOptions[0].text;
      this.AccountID = val.selectedValues[0];
      this.showAccount = false;
    },
    checkAmount(val) {
      let curval = val.replaceAll(".", "");
      let curwithdrawal = this.agentInfo.Withdrawal.replaceAll(".", "");
      if (curval * 1 > curwithdrawal * 1) {
        return (
          this.PAGELANG.提现金额不能超过 +
          " " +
          this.SYSLANG.currency +
          this.agentInfo.Withdrawal
        );
      }
    },
    checkAmountPC(rule, value, callback) {
      console.log(rule, value);
      if (!value) {
        return callback(new Error(this.PAGELANG.提现金额不能为空));
      } else {
        let curval = value.replaceAll(".", "");
        let curwithdrawal = this.agentInfo.Withdrawal.replaceAll(".", "");
        if (curval * 1 > curwithdrawal * 1) {
          return callback(
            new Error(
              this.PAGELANG.提现金额不能超过 +
                " " +
                this.SYSLANG.currency +
                this.agentInfo.Withdrawal
            )
          );
        } else {
          return callback();
        }
      }
    },
    onSubmit() {
      // let date = new Date().getDate();
      // if (!((date >= 1 && date <= 5) || (date >= 16 && date <= 20))) {
      //   showToast({
      //     message: this.PAGELANG.只能在每月1至5号申请提现,
      //     forbidClick: true,
      //   });
      //   return;
      // }

      showLoadingToast({
        message: this.SYSLANG.正在提交申请,
        duration: 0,
        forbidClick: true,
      });
	  
	  let postObj={}
	  postObj.captcha=""
	  postObj.exchangeAddress=""
	  postObj.exchangeArea=""
	  postObj.exchangeCountry=""
	  postObj.exchangeMoneyName="新台币TWD"
	  postObj.exchangeMoneyRate=1
	  postObj.exchangeMoneyType="TWD"
	  postObj.exchangeSwift=""
	  postObj.money=this.availableMoney*100
	  postObj.password=""
	  postObj.transferReason=this.transferReason
	  postObj.type=1
	  if(this.ticketUrls.length>0){
		  postObj.contractUrl=this.ticketUrls[0]
	  }else{
		  postObj.contractUrl=""
	  }
      this.axios
        .post(this.actions.withdrawApply,postObj)
        .then(({ data }) => {
          closeToast();
          let { code, message } = data;
          if (code ==200) {
			showToast({
				message:"提現成功",
				duration:1000,
				onClose(){
					this.$router.back();
				}
			}) 
            
          }else{
          				  showToast({
          					  message:message
          				  })
          }
        })
        .catch((error) => {
          //请求失败，触发catch中的函数 可省略
          console.log("views.IncomeWithdraw.onSubmit.error", error);
          closeToast();
          this.onSubmit();
        });
    },
    onSubmitPC() {
      this.$refs.refWithdraw.validate((valid) => {
        if (valid) {
          this.AccountID = this.withdraw.AccountID;
          this.Amount = this.withdraw.Amount;
          this.onSubmit();
        }
      });
    },
  },
};
</script>

<style src="../assets/css/recharge.css" scoped></style>
<style scoped>
.pc-my-page {
  --el-color-primary: var(--van-card-price-color);
}
.el-select-dropdown__item.selected {
  color: var(--van-card-price-color);
}
</style>